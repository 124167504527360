<template>
	<section class="s-alerts" v-if="data && data.length">
		<h2 class="accessible-hide">Alerts</h2>
		<div class="component c-alerts c-alerts--list">
			<ul class="c-alerts__list reset-list">
				<li
					class="c-alerts__item bg-color-danger line-height-100 padding-16 text-align-center text-on-danger
						bp-1024:display-flex bp-1024:align-items-center bp-1024:text-align-left bp-1024:padding-x-32"
					:class="{ 'border-t': index > 0 }"
					v-for="(item, index) in enabled_items"
					:key="index"
				>
					<alert-triangle-icon
						class="margin-b-16 bp-1024:margin-b-0"
					></alert-triangle-icon>
					<div
						class="c-alerts__text font-size-16 margin-b-16
							bp-1024:margin-b-0 bp-1024:padding-x-32 bp-1024:flex-item-1"
					>
						{{ item.title }}
					</div>
					<navigation-link
						_class="c-alerts__link border display-inline-block header-font padding-y-6 padding-x-16 text-on-danger text-decoration-none text-transform-uppercase hover:bg-color-white hover:text-danger transition bp-1024:margin-t-0"
						:target="item.open_in_new_tab ? '_blank' : '_self'"
						:url="item.link"
						v-if="item.link && item.link != ''"
						>Details</navigation-link
					>
				</li>
			</ul>
		</div>
	</section>
</template>

<script>
import { AlertTriangleIcon } from 'vue-feather-icons';
import NavigationLink from '@/components/common/NavigationLink';
import { get } from '@/helpers';

export default {
	name: 'Alerts',

	components: {
		AlertTriangleIcon,
		NavigationLink,
	},

	props: {
		dummy: {
			type: Boolean,
			default: false,
		},
		championshipId: {
			type: Number,
			default: 0,
		},
	},

	data: () => ({
		data: null,
	}),

	computed: {
		enabled_items() {
			return this.data.filter(item => {
				return item.enabled === true;
			});
		},
	},

	methods: {
		get_data() {
			const request_options = {
				type: 'alerts',
				championship_id: this.championshipId,
			};
			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			).then(response => {
				this.data = response;
			});
		},
	},

	created() {
		this.get_data();
	},
};
</script>

<style lang="scss">
.c-alerts--list {
	.c-alerts {
		//for naming
		&__link {
			border-color: var(--color-text-on-danger);
			border-radius: 9999px;
		}
	}
}
</style>
