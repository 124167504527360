<template>
	<section class="s-championships" v-if="featured_championships && featured_championships.length">
		<h2 class="accessible-hide">{{ sectionTitle }}</h2>
		<championships-list-filter
			v-if="$route.name === 'Home'"
			:championships="all_championships"
			@updateFilter="(filterResult) => filtered_championships = filterResult"
			class="margin-t-12 bp-1024:margin-t-0"
		/>
		<component
			:is="template"
			:data="$route.name === 'Home' && filtered_championships.length != all_championships.length 
				? sort_championships(filtered_championships) 
				: featured_championships_sorted"
			:route_name="
				template === 'ChampionshipsList' ? $route.name : undefined
			"
			:showAllChampionshipsLink="filtered_championships.length == all_championships.length"
			:show-tile-overlay-text="true"
		/>
	</section>
</template>

<script>
import { get, sort_by } from '@/helpers';
import ChampionshipsList from './templates/ChampionshipsList';
import ChampionshipsListFilter from './ChampionshipsListFilter';

export default {
	name: 'Championships',

	components: {
		ChampionshipsList,
		ChampionshipsListFilter
	},

	props: {
		championshipId: {
			type: Number,
			default: null,
		},
		start: {
			type: Number,
			default: null,
		},
		sportSlug: {
			type: String,
			default: null,
		},
		featured: {
			type: Boolean,
			default: false,
		},
		sectionTitle: {
			required: true,
			type: String,
		},
		template: {
			required: true,
			type: String,
		},
		sortOrder: {
			type: String,
		},
	},

	data: () => ({
		featured_championships: [],
		all_championships: [],
		filtered_championships: [],
	}),

	computed: {
		featured_championships_sorted() {
			return this.sort_championships(this.featured_championships);
		},
	},

	methods: {
		get_featured_championships() {
			const request_options = {
				type: 'championships',
			};

			if (this.championshipId) {
				request_options.championship_id = this.championshipId;
			}
			if (this.sportSlug) {
				request_options.sport_slug = this.sportSlug;
			}
			if (this.start) {
				request_options.start = this.start;
			}
			if (this.featured) {
				request_options.featured = this.featured;
			}

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			).then(response => {
				this.featured_championships = response.championships;
			});
		},

		get_all_championships() {
			const request_options = {
				type: 'championships',
			};

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			).then(response => {
				this.all_championships = response.championships;
				this.filtered_championships = response.championships;
			});
		},

		sort_championships(championships) {
			let _championships = championships.slice();

			if (this.sortOrder === 'division') {
				return _championships.sort(sort_by('division'));
			}

			return _championships
				.sort(sort_by('featured'))
				.sort(sort_by('title'));
		}
	},

	created() {
		this.get_featured_championships();
		this.get_all_championships();
	},

	watch: {
		$route() {
			this.get_featured_championships();
		},
	},
};
</script>
